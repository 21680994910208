const currency_formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
const currency_formatter2 = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2 });

function ParseDate(input) {
    date = new Date(input); // months are 0-based
    return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
}

function sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
        if ((new Date().getTime() - start) > milliseconds){
            break;
        }
    }
}